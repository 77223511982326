import React from 'react'
import moment from 'moment'

import { identicalTyres } from '../../../../shared/lib/identicalTyres'
import * as promotionTypes from '../../../../shared/constants/promotion-types'

import clsx from 'clsx'
import { Box, Grid, Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import {
  sortStrings,
  sortNumbers,
  sortSites,
  sortSitesPriority,
  sortBrands,
} from '../../../shared/utils/sort'
import { ORDER_ITEM_QTY_LIMIT, ORDER_ITEM_QTY_WARNING } from '../../../../shared/constants'
import { HTS_DEALER_ROLE, SALES_ROLE } from '../../../../server/constants/roles'
import { SITES_MAP } from '../../../../shared/constants/sites-map'
import { MERCEDES } from '../../../../shared/constants/hta-types'
import { PRODUCT_TYRE } from '../../../../shared/constants/product-types'
import { MALAYSIA } from '../../../../shared/constants/countries-map'

export const getStockLabel = (tyre, user, dense) => {
  if (!user || tyre.quantity === undefined) return
  if (user?.role !== SALES_ROLE && tyre.quantity <= 0) {
    return dense ? 'Out' : 'Out of stock'
  } else if (user?.role === SALES_ROLE || tyre.quantity < user?.itemQtyCap) {
    const availability = Math.min(user?.itemQtyCap, tyre.quantity)
    return dense ? `${availability} left` : `${availability} in stock`
  }
  return 'In stock'
}

export const getOrderItem = (tyre, cart) =>
  cart.data?.orderItems?.find?.((item) => identicalTyres(tyre, item.product))

export const getAvailability = (tyre, cart, user) => {
  if (!cart || !user) return 0
  let availability = tyre.quantity
  const orderItem = getOrderItem(tyre, cart)
  if (orderItem) {
    availability -= orderItem.quantity
  }
  return Math.max(availability, 0)
}

export const addToCart = async (tyre, quantity, cart, cartMutation, user) => {
  const cartItem = {
    ...tyre,
    tyreType: tyre.type,
    tyreClass: tyre.class,
    unitPrice: tyre.price,
    quantity: Number(quantity),
    promotionIds: tyre.promotions?.map((promo) => promo.id),
  }
  let newQuantity = quantity
  try {
    await cartMutation.mutateAsync(cartItem)
    const newAvailability = getAvailability(tyre, cart, user) - quantity
    newQuantity = Math.min(quantity, newAvailability)
    cart.refetch()
  } catch (error) {
    console.error(error)
  } finally {
    return newQuantity
  }
}

export const calculatePromotion = (tyre, cart, baseQuantity) => {
  let unitDiscount = 0
  let quantity = baseQuantity
  if (tyre.promotions?.length === 0) return unitDiscount

  const tyreInCart = getOrderItem(tyre, cart)
  if (tyreInCart) {
    quantity += tyreInCart.quantity
  }

  const promotions = tyre.promotions?.filter(
    (promo) => quantity >= promo.minPurchase && promo.type !== promotionTypes.GIFT
  )

  if (!promotions?.length > 0) return unitDiscount

  const promotion = promotions.reduce(
    (prev, curr) =>
      curr.minPurchase === Math.max(prev.minPurchase, curr.minPurchase) ? curr : prev,
    promotions[0]
  )

  if (promotion?.type === promotionTypes.AMOUNT) {
    unitDiscount = promotion.discountAmount
  } else if (promotion?.type === promotionTypes.PERCENTAGE) {
    unitDiscount = tyre.price * promotion.discountPercentage
  }
  return unitDiscount
}

const StockLabel = ({ tyre, user, dense }) => (
  <Typography
    variant='subtitle2'
    align='right'
    color={
      tyre.quantity === 0
        ? 'textSecondary'
        : tyre.quantity < ORDER_ITEM_QTY_WARNING
        ? 'error'
        : undefined
    }
  >
    {getStockLabel(tyre, user, dense)}
  </Typography>
)

export const renderNewLabel = (tyre, user) =>
  user?.htaConfig?.type === MERCEDES && moment().year().toString() === tyre.batch ? (
    <Typography variant='subtitle2' align='right'>
      New
    </Typography>
  ) : (
    tyre.batch
  )

export const renderStockLabel = (tyre, user, dense = false) =>
  user &&
  tyre.quantity !== undefined &&
  ((tyre.subSitesQty ? Object.keys(tyre.subSitesQty) : []).length > 0 ? (
    <Tooltip
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title={
        <span>
          {Object.entries(tyre.subSitesQty)?.map(([key, value]) => (
            <div key={key}>
              {SITES_MAP[user.country]?.mergedSitesDict[tyre.site]?.[key] || key}: {value} in stock
            </div>
          ))}
        </span>
      }
    >
      <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
        <Grid item>
          <Typography style={{ display: 'flex' }} color='textSecondary' variant='subtitle2'>
            <InfoIcon fontSize='inherit' />
          </Typography>
        </Grid>
        <Grid item>
          <StockLabel tyre={tyre} user={user} dense={dense} />
        </Grid>
      </Grid>
    </Tooltip>
  ) : (
    <StockLabel tyre={tyre} user={user} dense={dense} />
  ))

export const renderPrice = (formatCurrency, unitPrice, unitDiscount = 0, classes) => {
  if (unitPrice === null) return
  const discountedPrice = unitPrice - unitDiscount
  return (
    <>
      {unitPrice !== discountedPrice && (
        <Typography
          variant='body2'
          color='textSecondary'
          className={clsx(classes.oldPrice, classes.alignEnd)}
        >
          {formatCurrency(unitPrice)}
        </Typography>
      )}
      <div className={clsx(classes.finalPrice, classes.alignEnd)}>
        {formatCurrency(discountedPrice)}
      </div>
    </>
  )
}

export const sortSize = (a, b) =>
  sortNumbers(a.width, b.width) ||
  sortNumbers(a.profile, b.profile) ||
  sortNumbers(a.rimSize, b.rimSize) ||
  sortNumbers(a.loadIndex || 0, b.loadIndex || 0) ||
  sortStrings(a.speedRating || '', b.speedRating || '')

export const sortPromos = (a, b) => +(b.promotions?.length > 0) - +(a.promotions?.length > 0)

export const browseSort = (
  tyres,
  primarySite = 'H1',
  isSales = false,
  promoFirst = false,
  sortField = '',
  sortFunction = (a, b) => 0,
  dec = false,
  productType = PRODUCT_TYRE,
  country = MALAYSIA
) =>
  tyres.sort(
    (a, b) =>
      (promoFirst ? sortPromos(a, b) : 0) ||
      (sortField
        ? dec
          ? sortField === 'width'
            ? sortSize(b, a)
            : sortField === 'site'
            ? sortSitesPriority(b.site, a.site, primarySite, dec)
            : sortFunction(b[sortField], a[sortField])
          : sortField === 'width'
          ? sortSize(a, b)
          : sortField === 'site'
          ? sortSitesPriority(a.site, b.site, primarySite)
          : sortFunction(a[sortField], b[sortField])
        : 0) ||
      sortBrands(a.brand, b.brand, productType, country) ||
      (isSales
        ? sortStrings(a.pattern, b.pattern)
        : sortStrings(a.fullPattern || a.pattern, b.fullPattern || b.pattern)) ||
      sortNumbers(a.width, b.width) ||
      sortNumbers(a.profile, b.profile) ||
      sortNumbers(a.rimSize, b.rimSize) ||
      sortNumbers(a.loadIndex || 0, b.loadIndex || 0) ||
      sortStrings(a.speedRating || '', b.speedRating || '') ||
      sortStrings(a.homologation || '', b.homologation || '') ||
      sortStrings(a.type || a.tyreType || '', b.type || b.tyreType || '') ||
      sortStrings(a.characteristics || '', b.characteristics || '') ||
      sortSitesPriority(a.site, b.site, primarySite) ||
      sortStrings(b.batch || '', a.batch || '') ||
      sortNumbers(b.quantity, a.quantity)
  )
/**
 * Construct a label from tyre object
 * @param {Object} orderItem
 * @param {bool} includeHomologation default false
 * @param {bool} includeTyreClass default false
 * @returns string
 */
export const getTyreSize = (orderItem, includeHomologation = false, includeTyreClass = false) => {
  if (orderItem) {
    let tyreSize = `${orderItem.width || '*'}${orderItem.profile ? `/${orderItem.profile}` : ''}/${
      orderItem.rimSize || '*'
    }`
    if (!!orderItem.loadIndex && !!orderItem.speedRating)
      tyreSize += ` ${orderItem.loadIndex}${orderItem.speedRating}`

    // Append Homologation
    if (includeHomologation && orderItem.homologation) tyreSize += ' ' + orderItem.homologation
    // Append Tyre Class
    if (includeTyreClass && orderItem.tyre_class) tyreSize += ' ' + orderItem.tyre_class

    return tyreSize
  }
}
/**
 * Construct a label from hta tyre object
 * @param {Object} orderItem
 * @param {bool} includeHomologation default false
 * @param {bool} includeTyreClass default false
 * @returns string
 */
export const getHtaTyreSize = (
  orderItem,
  includeHomologation = false,
  includeTyreClass = false
) => {
  if (orderItem) {
    let tyreSize = `${orderItem.width || '*'}${orderItem.profile ? `/${orderItem.profile}` : ''}/${
      orderItem.rim_size || '*'
    }`
    if (!!orderItem.load_index && !!orderItem.speed_rating)
      tyreSize += ` ${orderItem.load_index}${orderItem.speed_rating}`

    // Append Homologation
    if (includeHomologation && orderItem.homologation) tyreSize += ' ' + orderItem.homologation
    // Append Tyre Class
    if (includeTyreClass && orderItem.tyre_class) tyreSize += ' ' + orderItem.tyre_class

    return tyreSize
  }
}

export const getTyreId = (tyre) =>
  tyre.brand +
  tyre.pattern +
  tyre.fullPattern +
  getTyreSize(tyre) +
  tyre.origin +
  tyre.batch +
  tyre.site +
  tyre.homologation +
  (tyre.type || tyre.tyreType || '') +
  tyre.characteristics +
  tyre.ev

export const getTyreTitle = (tyre, short = false) =>
  `${short ? tyre?.pattern || '' : tyre?.fullPattern || tyre?.pattern || ''}${
    tyre?.homologation ? ' ' + tyre?.homologation : ''
  }${tyre?.type || tyre?.tyreType ? ' ' + (tyre?.type || tyre?.tyreType) : ''}${
    tyre?.characteristics ? ' ' + tyre?.characteristics : ''
  }${tyre?.ev ? ' ' + tyre?.ev : ''}`

export const getTyreTitleHTA = (tyre, short = false) =>
  `${short ? tyre?.pattern || '' : tyre?.fullPattern || tyre?.pattern || ''}${
    tyre?.homologation ? ' ' + tyre?.homologation : ''
  }${tyre?.type || tyre?.tyre_type ? ' ' + (tyre?.type || tyre?.tyre_type) : ''}${
    tyre?.characteristics ? ' ' + tyre?.characteristics : ''
  }${tyre?.ev ? ' ' + tyre?.ev : ''}`

export const getTyreCode = (tyre) => {
  return `${tyre.width}${tyre.profile}${tyre.rimSize}`
}
