import React from 'react'
import { useAuth } from '../../hooks/context/AuthContext'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemAvatar from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import UserAvatar from './UserAvatar'
import { Chip, Divider, Typography } from '@material-ui/core'

const ProfileMenuItem = (props, ref) => {
  const { user, trialConfig, isLoading } = useAuth()

  return (
    <MenuItem ref={ref} {...props}>
      <ListItemAvatar>
        <UserAvatar user={user} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {user && user?.firstName && `${user?.firstName || ''} ${user?.lastName || ''}`.trim()}
            {trialConfig?.isTrial && (
              <div>
                <Chip size='small' label='TRIAL' />{' '}
                <Typography
                  variant='caption'
                  color={
                    trialConfig?.trialExpired || trialConfig?.warning ? 'error' : 'textSecondary'
                  }
                >
                  {trialConfig?.duration}
                </Typography>
              </div>
            )}
          </>
        }
        secondary={user && `${user?.email}`}
      />
    </MenuItem>
  )
}

export default React.forwardRef(ProfileMenuItem)
