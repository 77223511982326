const identicalTyres = (tyre, other) =>
  tyre.brand === other.brand &&
  tyre.pattern === other.pattern &&
  tyre.width === other.width &&
  tyre.profile === other.profile &&
  tyre.rimSize === other.rimSize &&
  tyre.speedRating === other.speedRating &&
  tyre.loadIndex === other.loadIndex &&
  tyre.homologation === other.homologation &&
  tyre.site === other.site &&
  tyre.origin === other.origin &&
  tyre.batch === other.batch

const identicalTyresAllSite = (tyre, other) =>
  tyre.brand === other.brand &&
  tyre.pattern === other.pattern &&
  tyre.width === other.width &&
  tyre.profile === other.profile &&
  tyre.rimSize === other.rimSize &&
  tyre.speedRating === other.speedRating &&
  tyre.loadIndex === other.loadIndex &&
  tyre.homologation === other.homologation &&
  tyre.origin === other.origin &&
  tyre.batch === other.batch

module.exports = { identicalTyres, identicalTyresAllSite }
