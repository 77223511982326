const {
  TYREHUB,
  PERFORMANCE,
  TRUCK,
  HOCKTYRE,
  HYPER,
  LUBRICANT,
  BRAKE,
  BATTERY,
  EKOTAYAR,
} = require('../constants/companies')
const { MALAYSIA, SINGAPORE } = require('../constants/countries-map')
const {
  CREDIT_LIMIT,
  BANKING_B2C,
  BANKING_B2B,
  CREDIT_CARD,
  EWALLET,
} = require('../constants/payment-methods')
const {
  PRODUCT_TYRE,
  PRODUCT_LUBRICANT,
  PRODUCT_BRAKE,
  PRODUCT_BATTERY,
} = require('../constants/product-types')
const regionalConfigs = {
  enablePaymentMethods: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableExcludeHtaStock: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  paymentMethods: {
    [MALAYSIA]: {
      [CREDIT_LIMIT]: {
        value: CREDIT_LIMIT,
        label: 'Available Credit',
        labelCheckout: 'Use Available Credit',
        enabled: true,
      },
      [BANKING_B2C]: {
        value: BANKING_B2C,
        label: 'Online Banking (FPX) Personal Account',
        enabled: true,
      },
      [BANKING_B2B]: {
        value: BANKING_B2B,
        label: 'Online Banking (FPX) Business Account',
        enabled: true,
      },
      [CREDIT_CARD]: {
        value: CREDIT_CARD,
        label: 'Credit/Debit Card',
        enabled: false,
      },
      [EWALLET]: {
        value: EWALLET,
        label: 'E-Wallet',
        enabled: false,
      },
    },
    [SINGAPORE]: {
      [CREDIT_LIMIT]: {
        value: CREDIT_LIMIT,
        label: 'Available Credit',
        labelCheckout: 'Use Available Credit',
        enabled: true,
      },
      [CREDIT_CARD]: {
        value: CREDIT_CARD,
        label: 'Credit/Debit Card',
        enabled: false,
      },
    },
  },
  currency: {
    [MALAYSIA]: 'MYR',
    [SINGAPORE]: 'SGD',
  },
  currencyLabel: {
    [MALAYSIA]: 'RM',
    [SINGAPORE]: 'SGD',
  },
  maxPrice: {
    [MALAYSIA]: 3500,
    [SINGAPORE]: 700,
  },
  enableDeliveryDate: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  showDeliveryTimeForCS: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  enableSubSites: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  addSiteIDForSalesFilter: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableKeepStockOrders: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  enableClaimPanel: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableProcessedOrders: {
    [MALAYSIA]: true,
    [SINGAPORE]: true,
  },
  enableTaxColumn: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  enableEditableAddress: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  enableTyreBatch: {
    [MALAYSIA]: true,
    [SINGAPORE]: true,
  },
  enablePromotions: {
    [MALAYSIA]: true,
    [SINGAPORE]: true,
  },
  enableTeams: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableHTSDealer: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableHTADealer: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableBillingSite: {
    [MALAYSIA]: true,
    [SINGAPORE]: true,
  },
  showStockOnlyForSales: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  requireItemRefForOrderItem: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  defaultHTGCompany: {
    [MALAYSIA]: TYREHUB,
    [SINGAPORE]: HOCKTYRE,
  },
  enableVisibleOrderSites: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableVisibleRoles: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  supportEmail: {
    [MALAYSIA]: 'htthsupport@htgroup.com.my',
    [SINGAPORE]: 'cs1@hocktyre.com',
  },
  siteLabel: {
    [MALAYSIA]: 'Site',
    [SINGAPORE]: 'Warehouse',
  },
  sitesLabel: {
    [MALAYSIA]: 'Sites',
    [SINGAPORE]: 'Warehouses',
  },
  mergeBatch: {
    [MALAYSIA]: '0', //merge batch
    [SINGAPORE]: '1', //normal year only value
  },
  fastDeliveryMode: {
    [MALAYSIA]: 'Fast Delivery by Taxi (Same Day)',
    [SINGAPORE]: 'Speedy Delivery (Payable)',
  },
  cartTax: {
    [MALAYSIA]: [],
    [SINGAPORE]: [{ name: 'GST (7%)', tax: 0.07 }],
  },
  enableRunflat: {
    [MALAYSIA]: true,
    [SINGAPORE]: true,
  },
  runflatTypes: {
    [MALAYSIA]: ['RFT'],
    [SINGAPORE]: ['ACS ZP', 'RF', 'R-F', 'RFT', 'ROF', 'RSC ROF', 'SSR', 'ZP', 'ZPS'],
  },
  enableAccountDetails: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  showCopyOrderSlug: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableFastDeliveryExpress: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  fastDeliveryExpress: {
    [MALAYSIA]: {
      message: 'Purchase at least 2 pieces of tyres that are either run-flat or above 19 inch',
      check: (cart) => {
        const minimumPurchase = 2
        let expressDeliveryTotal = 0

        const orderItems = cart?.orderItems

        orderItems?.map((item) => {
          if (
            this.runflatTypes[MALAYSIA].includes(item?.product?.tyreType) ||
            item?.product?.rimSize >= 19
          ) {
            expressDeliveryTotal += item?.quantity
          }
        })
        return expressDeliveryTotal >= minimumPurchase
      },
    },
    [SINGAPORE]: {
      message: '',
      check: () => {},
    },
  },
  enableVisibleOrderSites: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableVisibleRoles: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  showProductIDForSales: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  showReservedStock: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableHTGCompanies: {
    [MALAYSIA]: true,
    [SINGAPORE]: true,
  },
  enableFooter: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableManageTyreDatabase: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableClosingDate: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableEV: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableDeliverySchedule: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableDeliveryModeConfig: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  htgCompanies: {
    [MALAYSIA]: [TYREHUB, TRUCK, PERFORMANCE, LUBRICANT, BRAKE, BATTERY, EKOTAYAR],
    [SINGAPORE]: [HOCKTYRE, HYPER],
  },
  htgCompanyOptions: {
    [MALAYSIA]: [
      {
        value: TYREHUB,
        label: 'HT Tyrehub',
        productTypes: [PRODUCT_TYRE],
      },
      {
        value: TRUCK,
        label: 'HT Truck Service',
        productTypes: [PRODUCT_TYRE],
      },
      {
        value: EKOTAYAR,
        label: 'HT EkoTayar',
        productTypes: [PRODUCT_TYRE],
      },
      {
        value: PERFORMANCE,
        label: 'HT Performance Tyre',
        productTypes: [PRODUCT_TYRE],
      },
      {
        value: LUBRICANT,
        label: 'HT Lubricant',
        productTypes: [PRODUCT_LUBRICANT],
      },
      {
        value: BRAKE,
        label: 'HT Brakes',
        productTypes: [PRODUCT_BRAKE],
      },
      {
        value: BATTERY,
        label: 'HT Battery',
        productTypes: [PRODUCT_BATTERY],
      },
    ],
    [SINGAPORE]: [
      {
        value: HOCKTYRE,
        label: 'Hock Tyre',
        productTypes: [PRODUCT_TYRE],
      },
      {
        value: HYPER,
        label: 'Hyper Tyre & Battery',
        productTypes: [PRODUCT_TYRE],
      },
    ],
  },
  minimumPurchase: {
    [MALAYSIA]: 0,
    [SINGAPORE]: 0,
  },
  enableSalesCommission: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  salesCommissionEligiblePrice: {
    [MALAYSIA]: 500,
    [SINGAPORE]: undefined,
  },
  salesCommissionEligibleDays: {
    [MALAYSIA]: 30,
    [SINGAPORE]: undefined,
  },
  salesCommissionTiers: {
    [MALAYSIA]: {
      5: 20,
      10: 30,
      20: 50,
    },
    [SINGAPORE]: {},
  },
  enableLandingPage: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableSalesDashboard: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableTyreClaims: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  promoAdBanners: {
    [MALAYSIA]: [
      /* Commented out since its not needed, can be removed
      {
        srcMobile: '/images/promos/varta_c_m_w.png',
        src: '/images/promos/varta_c_w.png',
        alt: 'Varta promotion banner',
      },

      {
        srcMobile: '/images/promos/brembo_m.png',
        src: '/images/promos/brembo.png',
        alt: 'Brembo promotion banner',
      },*/
    ],
    [SINGAPORE]: [],
  },
  enableLalamove: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableSalesOrder: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableSearchCategory: {
    [MALAYSIA]: true,
    [SINGAPORE]: false,
  },
  enableSearchCard: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  // Promotion Config For SG
  enablePromoListFile: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  enableCustomDiscounts: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  enableDuplicatePromotion: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
  enablePromotionMixBrand: {
    [MALAYSIA]: false,
    [SINGAPORE]: true,
  },
}

module.exports = regionalConfigs
