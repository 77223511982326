import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = (size) =>
  makeStyles(() => ({
    avatarSize: {
      width: size,
      height: size,
      fontSize: size / 2,
    },
  }))

const UserAvatar = ({ user, size = 40 }, ref) => {
  /* TODO: Get user avatar image */
  const getInitial = (str = '') => {
    const trimmedString = str.trim()
    if (trimmedString.length > 0) {
      return trimmedString[0].toUpperCase()
    }
    return ''
  }
  const getUserInitials = () => {
    let initials = ''
    if (user) {
      if (user?.firstName) {
        initials += getInitial(user?.firstName)
        if (user?.lastName) {
          initials += getInitial(user?.lastName)
        }
      } else {
        initials += getInitial(user?.email)
      }
    }
    return initials
  }

  const classes = useStyles(size)()

  return !user ? (
    <Skeleton>
      <Avatar className={classes.avatarSize} />
    </Skeleton>
  ) : (
    <Avatar className={classes.avatarSize}>{getUserInitials()}</Avatar>
  )
}

export default React.forwardRef(UserAvatar)
