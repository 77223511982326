const ADMIN_PANEL = 'admin'
const HTA_BMW = 'hta bmw'
const HTA_MB = 'hta mb'
const HTA_JLR = 'hta jlr'
const HTA_VW = 'hta vw'
const HTA_BYD = 'hta byd'

const adminPermissionDict = {
  [ADMIN_PANEL]: 'admin',
  [HTA_BMW]: 'hta bmw',
  [HTA_MB]: 'hta mb',
  [HTA_VW]: 'hta vw',
  [HTA_BYD]: 'hta byd',
}

module.exports = { ADMIN_PANEL, HTA_BMW, HTA_MB, HTA_JLR, HTA_VW, HTA_BYD, adminPermissionDict }
