import React, { useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { Button, Container, Menu, MenuItem, ListItemText } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

import useNavBarStyles from './NavBar.styles'
import { useAuth } from '../../../hooks/context/AuthContext'
import { productDict } from 'shared/constants/product-types'
import { enablePromotions } from '../../../../../shared/config/regional-configs'
import { HTA_DEALER_ROLE, HTS_DEALER_ROLE } from '../../../../../server/constants/roles'

const NavBar = () => {
  const classes = useNavBarStyles()

  const { user, country, productTypes, canViewPrice } = useAuth()
  const history = useHistory()
  const disallowedRolesPromotion = [HTA_DEALER_ROLE, HTS_DEALER_ROLE]

  const enablePromotionsLink =
    user?.accessConfig?.promotions &&
    enablePromotions[country] &&
    !disallowedRolesPromotion.includes(user?.role)

  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const handleProductMenu = (event) => {
    setMenuAnchorEl(event?.currentTarget)
  }

  const handleProductMenuClose = () => {
    setMenuAnchorEl(null)
  }

  return (
    <Container className={classes.appBar} maxWidth={false}>
      <Button
        className={classes.button}
        variant='text'
        onClick={handleProductMenu}
        endIcon={<KeyboardArrowDown style={{ marginLeft: '20px', color: 'white' }} />}
      >
        Category
      </Button>
      <Menu
        id='search-products'
        getContentAnchorEl={null}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(menuAnchorEl)}
        onClose={handleProductMenuClose}
        className={classes.menu}
      >
        {productTypes?.map((productType) => (
          <MenuItem
            key={productType}
            component={RouterLink}
            to={`/browse/${productDict[productType]?.namePlural}`}
            onClick={handleProductMenuClose}
            className={classes.menuItem}
          >
            <ListItemText disableTypography className={classes.listItem}>
              {productDict[productType]?.labelPlural.toUpperCase()}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <RouterLink to='/'>
        <Button className={classes.button}>HOME</Button>
      </RouterLink>

      {enablePromotionsLink && canViewPrice && (
        <RouterLink to='/client-promotion'>
          <Button className={classes.button}>PROMOTIONS</Button>
        </RouterLink>
      )}
    </Container>
  )
}

export default NavBar
