const TYREHUB = 'HT TYREHUB SDN BHD'
const PERFORMANCE = 'HT PERFORMANCE TYRE SDN BHD'
const TRUCK = 'HT TRUCK SERVICE SDN BHD'
const LUBRICANT = 'HT LUBRICANT SDN BHD'
const BRAKE = 'HT BRAKES SDN BHD'
const BATTERY = 'HT ECO BATTERY SDN BHD'
const HOCKTYRE = 'Hock Tyre Pte Ltd'
const HYPER = 'Hyper Tyre & Battery Pte Ltd'
const EKOTAYAR = 'HT EKOTAYAR SDN BHD'

const htgCompanyDict = {
  [TYREHUB]: 'hwt',
  [PERFORMANCE]: 'htp',
  [TRUCK]: 'htt',
  [LUBRICANT]: 'htl',
  [BRAKE]: 'htb',
  [BATTERY]: 'hte',
  [HOCKTYRE]: 'HOCK',
  [HYPER]: 'HYPER',
  [BATTERY]: 'hte',
  [EKOTAYAR]: 'ekt',
}

function getHTGCompanyFullName(shortName) {
  return (
    Object.entries(htgCompanyDict).filter(([_, value]) => shortName === value)?.[0]?.[0] ??
    shortName
  )
}

module.exports = {
  TYREHUB,
  PERFORMANCE,
  TRUCK,
  LUBRICANT,
  BRAKE,
  BATTERY,
  HOCKTYRE,
  HYPER,
  htgCompanyDict,
  EKOTAYAR,
  getHTGCompanyFullName,
}
